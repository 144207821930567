import '../App.css';
import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect } from 'react'
import { MapContainer, CircleMarker, Popup, ScaleControl, FeatureGroup } from 'react-leaflet'
import Leaflet from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import BaseMap from "./basemap";

const MapView = ({basemap, setBasemap, selectedBasemap, setSelectedBasemap}) => {

    //markerの設定
    let DefaultIcon = Leaflet.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
    });
    Leaflet.Marker.prototype.options.icon = DefaultIcon;

    //緯度経度
    const position = [20, 150];

    //初期マップズームレベル
    const zoom = 3;

    const [geojsonData, setGeojsonData] = useState(null);
    const [precipitationData, setPrecipitationData] = useState([
        { id: 1, precipitation: 20 },
        { id: 2, precipitation: 35 },
    ]);

    useEffect(() => {
        fetch('/data/world.geojson')
            .then(response => {
                if (!response.ok) {
                    throw new Error('ネットワーク接続が不安定です ' + response.statusText);
                }
                return response.json();
            })
            .then(data => setGeojsonData(data)
            )
            .catch(error => console.error('GeoJSONファイルの読み込みに失敗しました。', error));
    }, []);

    return (
        <MapContainer center={position} zoom={zoom} preferCanvas={true} worldCopyJump={true}>
            <BaseMap
                basemap={basemap}
                setBasemap={setBasemap}
                selectedBasemap={selectedBasemap}
                setSelectedBasemap={setSelectedBasemap}
            />
            {geojsonData ? (geojsonData.features.map((feature, index) => {

                if (feature.geometry.coordinates[0] >= -25) {
                    return (
                        <FeatureGroup color="purple" key={index}>
                            <Popup>
                                <p>{feature.properties.city}</p>
                            </Popup>
                            <CircleMarker
                                center={[
                                    feature.geometry.coordinates[1],
                                    feature.geometry.coordinates[0]
                                ]}
                                fillColor="#ff7800"
                                radius={5}
                                color={"#000"}
                                weight={0.5}
                                opacity={1}
                                fillOpacity={0.8}
                            />
                        </FeatureGroup>
                    );
                }
                else {
                    return (
                        <FeatureGroup color="purple" key={index}>
                            <Popup>
                                <p>{feature.properties.city}</p>
                            </Popup>
                            <CircleMarker
                                center={[
                                    feature.geometry.coordinates[1],
                                    feature.geometry.coordinates[0] + 360
                                ]}
                                fillColor="#ff7800"
                                radius={5}
                                color={"#000"}
                                weight={1}
                                opacity={1}
                                fillOpacity={0.8}
                            />
                        </FeatureGroup>
                    );
                }
            })) : (
                <p>データを読み込み中です</p>
            )}
            <ScaleControl metric={true} imperial={false} position="bottomright" />
        </ MapContainer>
    )
}

export default MapView;