import '../App.css';
import 'leaflet/dist/leaflet.css';
import React from 'react'

const MapControles = ({ basemap, setBasemap, selectedBasemap, setSelectedBasemap, basemapState, setbasemapState }) => {

    return (
        <div id="controles">
            <button className="controle_button" onClick={() => { setbasemapState(basemapState => !basemapState) }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="white" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z" />
                </svg>
            </button>
            <button className="controle_button" >
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="white" viewBox="0 0 16 16">
                    <path d="M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882l-7.5-4zM8 9.433 1.562 6 8 2.567 14.438 6 8 9.433z" />
                </svg>
            </button>
        </div>
    )
}

export default MapControles;