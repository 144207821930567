import './App.css';
import React, { useState } from 'react'
import MapView from './map/mapview';
import MapControles from './map/mapcontroles';
import BaseMapSetting from './map/basemapsetting';

function App() {

  const [basemap, setBasemap] = useState([{
    id: 1,
    name: "国土地理院 標準地図",
    attribution: "&copy; <a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>国土地理院</a>",
    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
  },
  {
    id: 2,
    name: "国土地理院 淡色地図",
    attribution: "&copy; <a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>国土地理院</a>",
    url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png"
  },
  {
    id: 3,
    name: "国土地理院 白地図",
    attribution: "&copy; <a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>国土地理院</a>",
    url: "https://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png"
  },
  {
    id: 4,
    name: "OpenStreetMap",
    attribution: "&copy; <a href='http://osm.org/copyright' target='_blank'>OpenStreetMap</a>",
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  },
  {
    id: 5,
    name: "デジタル地図帳 for School",
    attribution: "&copy; 2024 <a href='https://www.hcpc.co.jp'>平凡社地図出版</a>",
    url: "https://www.hcpc.co.jp/tiles/{z}/{x}/{y}.png"
  }]);

  const [selectedBasemap, setSelectedBasemap] = useState("0");
  const [basemapState, setbasemapState] = useState(true);

  return (
    <>
      <MapView
        basemap={basemap}
        setBasemap={setBasemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
      />
      <MapControles
        basemap={basemap}
        setBasemap={setBasemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        basemapState={basemapState}
        setbasemapState={setbasemapState}
      />
      <BaseMapSetting
        basemap={basemap}
        setBasemap={setBasemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        basemapState={basemapState}
        setbasemapState={setbasemapState}
      />
    </>
  );
}

export default App;