import '../App.css';
import 'leaflet/dist/leaflet.css';
import React from 'react'
import { MapContainer, TileLayer, LayersControl, Marker, CircleMarker, Popup, Tooltip } from 'react-leaflet'
import Leaflet from "leaflet";

const BaseMap = ({basemap, setBasemap, selectedBasemap, setSelectedBasemap}) => {

    const name = basemap[selectedBasemap].name
    const attribution = basemap[selectedBasemap].attribution
    const url = basemap[selectedBasemap].url

    return (
          <TileLayer
            attribution={attribution}
            url={url}
          />
    )
}

export default BaseMap;